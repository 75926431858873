import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import storeData from "../data/storeData";
import "../css/StoreSelector.css";
import showSelector from "./Navbar";

const StoreSelector = ({ setShowSelector }) => {

    useEffect(() => {
        // Disable background scroll when Comp B is shown
        document.body.style.overflow = 'hidden';

        // Cleanup function to re-enable scroll when Comp B is closed
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className="store-selecotr-overlay">
            <div className="store-selector-wrapper">
                <div className="store-selector-container">
                    <div className="store-selector-header">
                        <div className="store-selector-header-label">
                            <h2>Select a Store</h2>
                            <button onClick={() => setShowSelector(false)}> <FontAwesomeIcon icon={faXmark} /> </button>
                        </div>

                        <div className="store-selector-header-searchbar">
                            <div className="store-selector-header-searchbar-container">
                                <input type="text" placeholder="Search nearest store by postal code" />
                                <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                            </div>
                            <button className="store-selector-header-searchbar-location"><FontAwesomeIcon icon={faLocationArrow} /></button>
                        </div>

                    </div>

                    <div className="store-selector-list-container">
                        <ul className="store-selector-list">
                            {storeData.filter(store => store.id !== 7 && store.id !==8).map((store, index) => (
                                <li key={index} className="store-selector-list-item">
                                    <div className="store-selector-list-item-wrapper">
                                        <div className="store-selector-list-name">
                                            <h3>{store.name}</h3>
                                        </div>
                                        <div className="store-selector-list-details">
                                            <div className="store-selector-list-time">
                                                {store.hours.map((hour, index) => (
                                                    <p key={index}>
                                                        <span style={{fontWeight: "500"}}>{hour.day}. </span>
                                                        <span style={{color: "#555"}}>{hour.time}</span>
                                                    </p>
                                                ))}
                                            </div>
                                            <div className="store-selector-list-address">
                                                <a href={store.mapLink} target="_blank" rel="noreferrer" title="Google Map">{store.address}, {store.city}, {store.province} {store.zip}</a>
                                            </div>
                                            <div className="store-selector-list-phone">
                                                <p>{store.phone}</p>
                                            </div>
                                        </div>
                                        <div className="store-selector-list-setdefault">
                                            <a href={store.siteUrl}>Select this store</a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default StoreSelector;