const policyData = {
    
    title: "FM SEAFOOD PRIVACY POLICY",
    content: [
        {
            id: 1,
            subtitle: "",
            content: ["This Privacy Policy explains how FM seafood/The fisherman’s market/fm.access-point.xyz/ (“FM,” “we,” “us,” or “ours”) collects, uses, and discloses information through our websites located at thefishermansmarket.ca, including through mobile access (collectively, the “Website”), and through our other online services that link to this Privacy Policy (collectively, the “Services”) or when you otherwise interact with us."]
        },
        {
            id: 2,
            subtitle: "",
            content: ["We may change this Privacy Policy from time to time. If we make changes, we will notify you of the updated policy by posting the updated policy through the Website, adding a statement to our homepage, or by other reasonable means."]
        },
        {
            id: 3,
            subtitle: "",
            content: ["The Last Updated legend above indicates when the policy was last updated. If you visit the Website or use the Services after a change to this Privacy Policy is posted on the Website, you will be bound by such change. We encourage you to check to see if the Privacy Policy has been updated since your last visit whenever you visit the Website and to review the Privacy Policy whenever you access the Services or otherwise interact with us so that you can stay informed about our information collection, use, and disclosure practices and the choices available to you in connection with our practices."]
        },
        {
            id: 4,
            subtitle: "Collection of Information",
            content: [
                "Information You Provide to Us",
                "We collect information that you provide directly to us. For example, when you register for an account, we ask you to provide certain information such as your name and email address. If you choose to purchase products and services from us, we ask you to provide your name, credit card information, billing address, shipping address, phone number, and email. If you choose to communicate with us via available electronic communication (e.g., email, online chat, or instant messaging), we may retain a complete copy of the electronic communication together with your email address. If you register through, visit the Website or otherwise communicate with us through a third-party social media platform, then we may receive information from such social media platforms in connection with such activities. We also collect information when you respond to our ads, respond to our surveys, receive technical support assistance from us or in connection with the Services, register through or otherwise communicate with us via third-party social media sites or participate in any interactive features of the Services (such as chat rooms, blogs, message or bulletin boards or new groups available to you through the Website).",
                "Automatically Collected Information",
                "When you access or use our Services, we automatically collect information about you, including:",
                "<b>Log Information:</b> We collect log information about your use of the Services, including the type of browser, app version, access dates and times, pages viewed, number of links clicked, your IP address, the name of your Internet service provider, search terms, operating system, website traffic and related statistics, keywords and the page you visited before navigating to our Services.",
                "<b>Device Information:</b> We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.",
                "<b>Information Collected by Cookies and Other Tracking Technologies:</b> We use different technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular and count visits. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits and understand usage and campaign effectiveness. JavaScript tags trigger a sequence of events that includes viewing a first-party cookie (or setting that cookie if it does not already exist). We may use JavaScript tags to help us tailor and optimize our Website and provide you with relevant FM advertisements outside our Website. For more information about cookies and how to disable them, please see “Your Choices” below.",
                "<b>Information We Collect from Other Sources:</b> We may obtain information from other sources and combine that with information we collect through our Services. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, account information, and friends lists, in accordance with the authorization procedures determined by such social media site.",
                ""
            ]
        },
        {
            id: 5,
            subtitle: "Children Under the Age of 13",
            content: ["Our Website is not intended for children under 13 years of age, and we do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, do not use or provide any information on this Website, register for the Services or use any other means to provide information to us about yourself, including your name, address, telephone number, email address or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13, we will take steps to delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at <b>Info@fmseafoods.com</b>."]
        },
        {
            id: 6,
            subtitle: "Content",
            content: [
                "We may make chat rooms, blogs, or similar forums available to you through the Website. Any content or personal information published on the Website is available to the public and accessible by any third party. You should exercise caution when disclosing personal information in these forums.",
                "If you participate in chat rooms, blogs, message or bulletin boards, news groups or similar forums, your username will be posted on the Website. Any content or personal information published on the Website may also appear in search engine results and the cache of those search engines, in feeds and third-party websites under cobranding agreements.",
                "We have no control over updating and/or removal of such content or personal information, which is the sole responsibility of the search engines, third-party websites and RSS web feed resources. FM is not responsible for the information published in search results or by any third-party website that carries any content or personal information published on the Website.",
                "We strongly discourage posting any personal information in public areas of the Website and urge you to be careful about giving out information on these features or in any content that you do not want others to see or use."
            ]
        },
        {
            id: 7,
            subtitle: "Use of Information",
            content: [
                "We use the information we collect to provide, maintain, and improve our services, such as permitting our third-party service providers (each, a “Provider”) to process purchases via the Website to deliver the products you request and customize your experience with us. For example, we may share your credit card and other payment-related information with our Providers as necessary to process your payment. We may also use the information we collect to:",
                "Provide the Services, including displaying certain personal information through your account on the Website for purposes of providing the Services;",
                "Complete or facilitate transactions that you initiate;",
                "Administer access to the Website and the Services, including sending you technical notices, updates, security alerts and support and administrative messages and responding to your comments, questions and customer service requests;",
                "Communicate with you about products, services, offers and events offered by FM and others, and provide news and information we think will be of interest to you;",
                "Monitor and analyze trends, usage and activities in connection with our Services;",
                "Detect, investigate, and prevent fraudulent transactions and other illegal activities, enforce Website policies, protect the rights and property of FFM and others and comply with applicable law;",
                "Personalize and improve the Website and the Services, send surveys and provide advertisements, including personalized advertisements, content, or features that match user profiles or interests;",
                "Facilitate contests, sweepstakes and promotions and process and deliver entries and rewards; and carry out any other purpose described to you at the time the information is collected"
            ]
        },
        {
            id: 8,
            subtitle: "Sharing of Information",
            content: [
                "We may share information, including aggregated information, about you as follows or as otherwise described in this Privacy Policy:",
                "Any credit card information that is provided shall be collected and transmitted in encrypted form directly by FFM’s third-party credit card payment provider and shall be used only to bill you for the products and services you purchased. FFM does not save any of your credit card information on its server(s) at any time;",
                "With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;",
                "With third parties (who may combine such information with their records and records available from other sources) for their own services and marketing purposes, and the direct marketing purposes of other third-party marketers, unless you opt out of this type of sharing by sending an email with your name and address to <a href=\"mailto:info@fmseafoods.com\">Info@fmseafoods.com</a>.",
                "In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements;",
                "If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of FM or others;",
                "In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;",
                "Between and among FM and our current and future parents, affiliates, subsidiaries and other companies under common control and ownership; and",
                "With your consent or at your direction."
            ]
        },
        {
            id: 9,
            subtitle: "Social Sharing Features",
            content: [
                "The Services may offer social sharing features and other integrated tools (such as the Facebook “Like” button), which let you share actions you take on our Services with other media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features."
            ]
        },
        {
            id: 10,
            subtitle: "Links to Third Party Websites",
            content: [
                "This Privacy Policy does not address, and we are not responsible for, the privacy, information or other practices of any third parties, including any third party operating any website or service to which the Services link. The inclusion of a link on the Services does not imply endorsement of the linked site or service by us or by our affiliates. We encourage you to learn about the privacy practices of those third parties prior to providing them with information or taking advantage of an offer or promotion."
            ]
        },
        {
            id: 11,
            subtitle: "Advertising and Analytics Services Provided by Others",
            content: [
                "We may allow others to provide analytics services and serve advertisements on our behalf across the web and in mobile applications. These entities may use cookies, web beacons, device identifiers and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked and conversion information. This information may be used by FFM and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and better understand your online activity."
            ]
        },
        {
            id: 12,
            subtitle: "Data Retention",
            content: ["We store the information we collect on you for as long as is necessary for the purpose(s) for which we originally collected it, or for other legitimate business purposes, including to meet our legal, regulatory or other compliance obligations."]
        },
        {
            id: 13,
            subtitle: "Security",
            content: [
                "Unfortunately, the transmission of information via the Internet is not completely secure, and no security system is impenetrable. We cannot guarantee the security of the databases where your personal information is stored and processed, nor can we guarantee that the information that you supply will not be intercepted while being transmitted to and from us over the internet, including, without limitation, information included within emails. Any transmission of personal information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on the Website.",
                "For purposes of clarification, we may use third-party cloud service providers that provide hosting, data storage and other services pursuant to standard terms and conditions that may be non-negotiable; these service providers have informed us or the general public that they apply security measures they consider adequate for the protection of information within their systems, or they have a general reputation for applying such measures."
            ]
        },
        {
            id: 14,
            subtitle: "Your Choices",
            content: []
        },
        {
            id: 15,
            subtitle: "Account Information",
            content: ["You may update, correct or delete your information at any time by logging into your account and accessing the “My Account” section of the Website. If you wish to cancel your account, please email us at info@fmseafoods.com, but note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time."]
        },
        {
            id: 16,
            subtitle: "Location Information",
            content: [
                "When you first visit our Website from a mobile browser, you may be asked to consent to the application’s collection of locational information. If you initially consent to our collection of location information, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device. If you do so, certain features of our Website may no longer function properly. Please be sure to manage your mobile device and privacy preferences for the Services on a consistent basis."
            ]
        },
        {
            id: 17,
            subtitle: "Cookies",
            content: [
                "Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services."
            ]
        },
        {
            id: 18,
            subtitle: "Promotional Communications",
            content: [
                "We offer you control over your privacy preferences regarding promotional email. You may update these preferences at any time. Please allow sufficient time for your preferences to be processed.",
                "You may opt out of receiving promotional emails from FM regarding new features, services, special offers, advertisements, promotions and our newsletter by unchecking the box that asks whether you would like to receive e-mail updates when you register for an account. You may also unsubscribe by following the instructions in any promotional email you receive from FM, which will contain a mechanism that can be used to unsubscribe from future promotional email messages from us or the applicable business division (although there may be a lag period before your request takes effect). Additionally, you may unsubscribe from our newsletter by signing into your account. Please pay careful attention to the various options for unsubscribing. You may have the option to unsubscribe from one or more special interest lists or unsubscribe from all promotional e-mails.",
                "If you opt out, we may still send you (1) emails that are primarily non-promotional (such as order confirmations or notices about your account or our ongoing business relations); and (2) postal mail."
            ]
        },
        {
            id: 19,
            subtitle: "Other Communications",
            content: [
                "We may send to you legally required notifications and other notices including, but not limited to, service-related notices or transactional communications related to your account. For example, we may send to you a notice regarding server problems or scheduled maintenance to the Website. To opt out of receiving these notices, you may need to deactivate your account."
            ]
        },
        {
            id: 20,
            subtitle: "Questions Concerning This Privacy Policy",
            content: [
                "If you have any comments or questions regarding this Privacy Policy please contact FM via email at <b>Info@fmseafoodst.com</b> “PRIVACY POLICY” in the subject line."
            ]
        }
    ]
    
}
export default policyData