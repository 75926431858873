import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../css/Navbar.css';
import logoImage from '../images/FMS_horizontal-logo-RGB-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCartShopping, faUser, faXmark, faBars, faSignOutAlt, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useUser } from '../context/UserContext';
import SearchResult from './SearchResult';
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import NavBarLink from './NavBarLink';
import CountdownBanner from './CountdownBanner';
import StoreSelector from './StoreSelector';

const Navbar = () => {
  const { user, isLoggedIn, signOut } = useUser();
  const { getTotalItems } = useCart();
  // State to track if the search input should be shown
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [showSelector, setShowSelector] = useState(false);
  // Function to toggle the search input visibility
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // Axios configuration
  const axiosInstance = axios.create({
      baseURL: 'https://fm.access-point.xyz/wp-json/wc/',
      auth: {
          username: process.env.REACT_APP_API_KEY,
          password: process.env.REACT_APP_API_SECRET,
      },
      params: {
          // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
      }
  });

  const fetchData = useCallback((searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    axiosInstance.get(`v3/products?search=${searchQuery}`).then(res => {
        setSearchResults(res.data);
        setLoading(false);
    });
  }, [searchQuery]);

  const searchBarRef = useRef(null);

  const toggleOff = () => {
    setShowSearch(false);
  }

  const handleDataFromCountdown = (data) => {
    return;
  }

  const debounce = (func, delay) => {
    let timeoutId;
    
    return function(...args) {
      const context = this;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const debounceSearch = useCallback(debounce((query) => {
      if (query.trim() === '') {
          setSearchResults([]);
          setLoading(false);
          return;
      }
      fetchData(query);
  }, 300), [fetchData]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    e.target.value === '' ? setShowSearch(false) : setShowSearch(true)
    debounceSearch(e.target.value);
  }

  const handleSearchBarClose = () => {
    setSearchQuery('');
    setShowSearch(false);
    debounceSearch('');
  }

  // State to hold whether the page has been scrolled down
  const [scrolled, setScrolled] = useState(false);
  // Effect hook to add/remove scroll event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        toggleOff();
      }
    };
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const getStoreInfo = () => {
    const hostname = window.location.hostname;
    switch (hostname) {
      case 'ne.fm.access-point.xyz':
        return { name: 'Nelson, BC', hours: '10:30 am – 5:30 pm' };
      case 'gi.fm.access-point.xyz':
        return { name: 'Gibsons, BC', hours: '10:00 am – 5:00 pm' };
      case 'ke.fm.access-point.xyz':
        return { name: 'Kelowna, BC', hours: '10:30 am – 5:30 pm' };
      default:
        return { name: 'Nelson, BC', hours: '10:30 am – 5:30 pm' };
    }
  };

  const storeInfo = getStoreInfo();

  return (
    <>
    <div ref={searchBarRef} className={scrolled ? 'navbar scrolled' : 'navbar'}>
      <div className="navbar-top-wrapper">
        <div className="navbar-top">
          <Link to="/" className="navbar-logo">
            <img src={logoImage} alt="Logo" className="logo" />
          </Link>

          <div className="navbar-store-locator">
            <button className="store-locator-button" onClick={(e) => setShowSelector(true)}>
              <span className="store-locator-icon"><FontAwesomeIcon icon={faLocationDot} /></span>
              <div className="store-locator-name">
                <span className="store-locator-selected">{storeInfo.name}</span>
                <div className="store-locator-timing">
                  <span>{storeInfo.hours}</span>
                </div>
              </div>
            </button>
          </div>
          
          <div className="search-bar">
            <input type="text" placeholder="Search for products" value={searchQuery} onChange={handleInputChange} />
            {showSearch && 
              (loading ? <span className="search-bar-control loading"><ClipLoader size={12} color='#173050' /></span>
                : <span className="search-bar-control close" onClick={handleSearchBarClose}><FontAwesomeIcon icon={faXmark} /></span>)
            }
          </div>

          <div className="navbar-buttons">
            <button onClick={() => setShowMenu(true)} className="menu-button">
              <FontAwesomeIcon icon={faBars} />
            </button>
            <Link to={`/search-results/${searchQuery}`} onClick={handleSearchBarClose} className="search-button">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Link>
            <Link to="/cart" className="cart-button">
              <FontAwesomeIcon icon={faCartShopping} />
              {getTotalItems() > 0 && <span className="cart-count">{getTotalItems()}</span>}
            </Link>
            {isLoggedIn ? (
              <>
                <Link to="/customer/account" className="signin-button">
                  Account
                </Link>
                <Link className="signout-button" onClick={signOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </Link>

              </>

            ) : (
              <Link to="/customer/sign-in" className="signin-button">
                <FontAwesomeIcon icon={faUser} />
              </Link>
            )}
          </div>
        </div>

        <div className="navbar-bottom">
            {showMenu && (
              <div className="toggle-menu">
                <ul className={scrolled ? 'navbar-bottom-small scrolled' : 'navbar-bottom-small'}>
                  <li>
                    <a onClick={() => setShowMenu(false)}>
                      <FontAwesomeIcon icon={faXmark} />
                    </a>
                  </li>
                  <li><Link to="/" onClick={() => setShowMenu(false)}>HOME</Link></li>
                  <li><Link to="/shop" onClick={() => setShowMenu(false)}>SHOP ONLINE</Link></li>
                  <li><Link to="/our-market" onClick={() => setShowMenu(false)}>OUR MARKET</Link></li>
                  <li><Link to="/locations" onClick={() => setShowMenu(false)}>LOCATIONS</Link></li>
                  <li><Link to="/recipes" onClick={() => setShowMenu(false)}>RECIPES</Link></li>
                  <li><Link to="/blog" onClick={() => setShowMenu(false)}>RESOURCES</Link></li>
                  <li><Link to="/fundraiser" onClick={() => setShowMenu(false)}>FUNDRAISERS</Link></li>
                  <li><Link to="/about-us" onClick={() => setShowMenu(false)}>ABOUT US</Link></li>
                  <li><Link to="/shipping" onClick={() => setShowMenu(false)}>SHIPPING</Link></li>
                </ul>
                <div className="navbar-overlay" onClick={() => setShowMenu(false)}></div>
              </div>
            )}

            <ul className="navbar-bottom-normal">
              <li><Link to="/shop" style={{ fontWeight: 'bold', color: '#e9a343' }}>SHOP ONLINE</Link></li>
              <li><Link to="/our-market">OUR MARKET</Link></li>
              <li><Link to="/locations">LOCATIONS</Link></li>
              <li><Link to="/recipes">RECIPES</Link></li>
              <li><Link to="/blog">RESOURCES</Link></li>
              <li><Link to="/fundraiser">FUNDRAISERS</Link></li>
              <li><Link to="/about-us">ABOUT US</Link></li>
              <li><Link to="/shipping">SHIPPING</Link></li>
            </ul>
          </div>

      </div>
      {showSelector && <StoreSelector setShowSelector={setShowSelector} />}

      {showSearch &&
          <div className={searchResults.length === 0 ? "search-results hide" : "search-results"}>
            <div className="search-results-wrapper">
              {searchResults.map((result) => (
                  <Link key={result.id} to={`/product/${result.id}`} onClick={(e) => setShowSearch(false)} className="result-list">
                      <div className='result-image' style={{ backgroundImage: `url(${result.images[0].src})` }} />
                      <div className='result-info'>
                          <h3>{result.name}</h3>
                          <div>
                              <span className="price">
                                  ${result.price}
                                  <span className="price-uom">{result.meta_data?.find(meta => meta.key === "unit_of_sale").value}</span>
                              </span>
                              {result.on_sale &&
                                  <span className="regular-price">
                                      ${result.regular_price}
                                      <span className="price-uom">{result.meta_data?.find(meta => meta.key === "unit_of_sale").value}</span>
                                  </span>
                              }
                          </div>
                      </div>
                  </Link>
              ))}
            </div>
          </div>
      }
    </div >
    <CountdownBanner smSize={false} isShowButton={true} dataToParent={handleDataFromCountdown}/>
    </>
  );
};

export default Navbar;
