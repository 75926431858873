import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import validator from "validator";
import '../css/SignUpButtom.css';
import { axiosMailChimpInstance } from '../mutations/checkout'
import { Link } from 'react-router-dom';

const SignUpButton = () => {
  const [value, setValue] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!termsAccepted) {
    //   alert('You must accept the terms and conditions');
    //   return;
    // }
    if (!name) {
      alert('Please enter your name');
      return;
    }
    const data = {
      'email': email,
      'name': name,
      'termsAccepted': true,
    }
    try {
      const res = await axiosMailChimpInstance.post('/mailchimp/register', data);
      console.log("response: ", res)
      if (res.status === 200) {
        alert('Thank you for joining Fisherman’s Market! You now have a 20% off coupon in your inbox!');
        localStorage.setItem('isSubscribed', JSON.stringify(true));
      } else if (res.status === 400 && res.data.code === 'mailchimp_permanently_deleted') {
        // user has been permenantly deleted, have to use the MailChimp portal to re-subscribe
        window.location.href = 'http://eepurl.com/haDB35';
      } else {
        console.log('Failed to subscribe.');
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error: ", error);
        alert(error.response.data.message);

        if (error.response.status === 400 && error.response.data.code === 'mailchimp_subscribed') {
          localStorage.setItem('isSubscribed', JSON.stringify(true));
        } else if (error.response.status === 400 && error.response.data.code === 'mailchimp_permanently_deleted') {
          window.location.href = 'http://eepurl.com/haDB35';
        } else {
          console.log('Failed to subscribe: ' + (error.response.data.message || 'Unknown error'));
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error during registration:", error);
        alert('An error occurred. Please try again later.');
      }
    }
  };

  const handleOnFocusOut = (tempEmail) => {
    console.log("MailChimp onfocusout: ", tempEmail);
    if (!validator.isEmail(tempEmail)) {
      setEmail("");
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div className="signup-container">
      <form id="SignUp" className="signup-wrapper">
        <p className="signup-title">Sign up now and get 20% off your first order!</p>
        <div className="input-container">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onBlur={(e) => handleOnFocusOut(e.target.value)}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <PhoneInput
            placeholder="Enter phone number (Optional)"
            value={value}
            onChange={setValue}
            defaultCountry="CA"
          />
        </div>
        {/*<div className="terms-condition-box">
            <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                required
            /> I accept the <Link to="/terms-conditions" style={{color: "#4c7ebc", fontWeight: "500"}}>Terms and Conditions</Link>
        </div>*/}
        <button className="signup-button" onClick={handleSubmit}>SIGN ME UP</button>
        <p className="signup-disclaimer">
          By entering your phone number and submitting this form, you consent to receive marketing text messages (such as promotion codes and cart reminders) from fm.access-point.xyz at the number provided, including messages sent by autodialer. Consent is not a condition of any purchase. Message and data rates may apply. Message frequency varies. You can unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available) in one of our messages. View our Terms & Conditions.
        </p>
      </form>
    </div>
  );
};

export default SignUpButton;
