import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../css/ProductDetails.css';
import { faFacebook, faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import SideBar from './SideBar';
import { useCart } from '../context/CartContext';

import ClipLoader from "react-spinners/ClipLoader";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import storeData from '../data/storeData'



const Description = ({ product }) => {

    return (
        <>
            <p className="product-biography" dangerouslySetInnerHTML={{ __html: product.description }}>
            </p>
        </>
    );
};

const Product = () => {

    const { productId } = useParams();
    const { cartItems, addItemToCart, updateItemQuantity, getItemQuantity, isItemInCart } = useCart();

    const [isLoading, setIsloading] = useState(true);
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const [product, setProduct] = useState({ meta_data: [] });
    const [quantity, setQuantity] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const unitOfSale = product.meta_data.find(meta => meta.key === "unit_of_sale")?.value;

    const apiUrl = 'https://fm.access-point.xyz/wp-json/wc/';
    const consumerKey = process.env.REACT_APP_API_KEY;
    const consumerSecret = process.env.REACT_APP_API_SECRET;

    const [storeMapLink, setStoreMapLink] = useState('https://www.google.com/maps/place/Fisherman\'s+Market/@49.4914545,-117.295339,17z/data=!3m1!4b1!4m5!3m4!1s0x537cb6a60d9993e9:0xc700205059d74e96!8m2!3d49.4914395!4d-117.2931857');
    const [storeInfo, setStoreInfo] = useState({
        address: '577 Ward Street',
        phone: '(250) 505-5515',
        city: 'Nelson',
        province: 'BC',
        zip: 'V1L 1T1'
    });

    const axiosInstance = axios.create({
        baseURL: apiUrl,
        auth: {
            username: consumerKey,
            password: consumerSecret,
        }
    });

    const fetchProduct = async (productId) => {
        setIsloading(true)
        await axiosInstance.get(`v3/products/${productId}`).then(res => {
            const item = cartItems.find(cartItem => cartItem.id === Number(productId));
            const retrievedProduct = res.data;
            console.log(res)
            setQuantity(item ? item.quantity : 1);
            setProduct(retrievedProduct);
            setSelectedImage(retrievedProduct.images[0].src);
            setIsloading(false)
        });
    };

    useEffect(() => {
        fetchProduct(productId)

    }, [productId]);


    // Issue: Once you have one item in cart added you dont have the option to remove it form the ProductListItem
    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
        updateItemQuantity(product, Number(event.target.value));
        if (!isSideBarVisible) {
            toggleSideBar()
        }
    };

    const handleAddToCart = (product) => {
        addItemToCart(product);
        if (!isSideBarVisible) {
            toggleSideBar()
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'CAD',
                add: {
                    products: [{
                        id: product.id,
                        name: product.name,
                        // category: product.category,
                        price: product.price,
                        quantity: 1
                    }]
                }
            }
        });
    };

    const handleStoreChange = (event) => {
        const link = storeData.find(store => store.id === Number(event.target.value)).mapLink;
        const store = storeData.find(store => store.id === Number(event.target.value));
        setStoreInfo({
            address: store.address,
            phone: store.phone,
            city: store.city,
            province: store.province,
            zip: store.zip
        });
        setStoreMapLink(link);
    };

    useEffect(() => {
        const item = cartItems.find(cartItem => cartItem.id === Number(productId));
        setQuantity(item ? item.quantity : 1);
    }, [cartItems]);

    useEffect(() => {
        console.log("Quantity has changed")
        console.log(quantity)
    }, [quantity]);

    // There is a better way to implement this. Sidebar should not be created as many times as the product items. 
    const toggleSideBar = () => {
        setIsSideBarVisible(!isSideBarVisible);
    };

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };
    const processedAttributes = useMemo(() => {
        return product.meta_data
            .filter(item => !item.key.startsWith('fb') && !item.key.startsWith('fcrm') && !item.key.startsWith('_') && item.value && !item.key.includes('unit_of_sale'))  // Filter out keys with '_' and 'unit of sale'
            .map(item => ({
                name: item.key,
                options: [item.value],
            }));
    }, [product.meta_data]);

    const savedPercentage = ((product?.regular_price - product?.price) / product?.regular_price) * 100;
    const roundedSavedPercentage = Math.round(savedPercentage);

    function toTitleCase(str) {
        return str
            .replace(/_/g, ' ') // replace underscores with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
    function addBreaksToStrongTags(str) {
        return str
            .replace(/<strong>/g, '<br/><br/><strong>')
            .replace(/<\/strong>/g, '</strong><br/><br/>');
    }

    if (isLoading) {
        return (
            <>
                <div className="product-detail-wrapper">
                    <div className="image-gallery">
                        <div className="main-image-container">
                            <Skeleton style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }} />
                        </div>
                        <div className="image-thumbnails">
                            <Skeleton className="thumbnail" />
                            <Skeleton className="thumbnail" />
                            <Skeleton className="thumbnail" />
                        </div>
                    </div>
                    <div className="product-info-detail">
                        <div style={{ position: "relative", height: "36px", marginBottom: "5px" }}>
                            <Skeleton height={36} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "36px", marginBottom: "30px" }}>
                            <Skeleton height={36} style={{ position: "absolute", left: "0", right: "0", width: "50%" }} />
                        </div>
                        <div style={{ position: "relative", height: "28px" }}>
                            <Skeleton height={28} width={120} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", margin: "14px 0" }}>
                            <Skeleton count={5} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "28px" }}>
                            <Skeleton height={28} width={120} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>


                        <div style={{ position: "relative", height: "50px", marginTop: "30px" }}>
                            <Skeleton height={50} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ position: "relative", height: "50px", marginTop: "15px" }}>
                            <Skeleton height={50} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>

                        <div style={{ position: "relative", height: "20px", margin: "20px 0 15px" }}>
                            <Skeleton height={20} width={100} style={{ position: "absolute", left: "0", right: "0" }} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                            <Skeleton circle height={20} width={20} style={{ marginRight: "10px" }} />
                        </div>

                    </div>
                </div >

                <div className="product-description">
                    <Skeleton height={25} width={120} style={{margin: "20px 0"}}/>
                    <Skeleton count={5}/>
                    <Skeleton height={25} width={120} style={{margin: "20px 0"}}/>
                    <Skeleton count={5}/>
                </div>
            </>
        ); // or some loading spinner
    } else {
        // Now you can safely access product and selectedImage values
        return (
            <>
                <div className="product-detail-wrapper">
                    <div className="image-gallery">
                        <div className="main-image-container">
                            <div className="main-image" style={{ backgroundImage: 'url(' + selectedImage + ')' }}>
                            </div>
                            {product.on_sale && <span className="sale-label"></span>}
                            {product.tags.some(tag => tag.name === "Premium") && <span className="premium-label"></span>}
                            {product.tags.some(tag => tag.name === "Feature") && <span className="feature-label"></span>}
                        </div>
                        <div className="image-thumbnails">
                            {product.images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.src}
                                    alt={`Thumbnail ${index}`}
                                    className={`thumbnail ${selectedImage === image.src ? 'selected' : ''}`}
                                    onClick={() => handleThumbnailClick(image.src)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="product-info-detail">
                        <h2>{product.name}</h2>
                        <div>
                            <span className="price">
                                ${product.price}
                                <span className="price-uom">{unitOfSale}</span>
                            </span>
                            {product.on_sale &&
                                <span className="regular-price">
                                    ${product.regular_price}
                                    <span className="price-uom">{unitOfSale}</span>
                                </span>
                            }
                            {product.on_sale &&
                                <span className="sale-badge">Save {roundedSavedPercentage}%
                                </span>
                            }
                        </div>
                        <Description product={product} />
                        {product.virtual === true ? 
                            <>
                                <p style={{margin: "0 0 .5rem"}}><b>Available Stores</b></p>
                                <div className="available-stores">
                                    <select name="stores" id="available_stores" className="available-stores-menu" onChange={handleStoreChange}>
                                        {storeData.map((store, index) => {
                                            return (
                                                <option key={index} value={store.id}>{store.name}</option>
                                            );
                                        })}
                                    </select>
                                    <p>{storeInfo.address}, {storeInfo.city}, {storeInfo.province} {storeInfo.zip}</p>
                                    <p>{storeInfo.phone}</p>
                                    <a href={storeMapLink} className="buy-now" target="_blank" rel="noreferrer" title="Google Map">VISIT HERE</a>

                                </div>
                            </> 
                            :
                            <>
                                <div className="quantity">
                                    <label htmlFor="quantity">Quantity:</label>
                                    <input type="number" id="quantity" name="quantity" min="1" value={quantity} onChange={handleQuantityChange} />
                                </div>

                                <button className="add-to-cart" onClick={() => handleAddToCart(product)}>ADD TO CART</button>
                                <Link to="/checkout" onClick={() => handleAddToCart(product)} className="buy-now">BUY NOW</Link>
                            </>
                        }

                        <div className="social-media-links">
                            <p>Share this to</p>
                            <div className="detail-social-icons">
                                <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                                <a href={`https://twitter.com/share?url=${window.location.href}&text=${product.name}`} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                <a href={`https://pinterest.com/pin/create/bookmarklet/?url=${window.location.href}&description=${product.name}`} target="_blank"><FontAwesomeIcon icon={faPinterest} /></a>
                            </div>
                        </div>
                    </div>
                </div >
                {product.virtual === false &&
                    <div className="product-description">

                        {product.attributes.map((attribute, index) => (
                            <div key={index}>
                                <h3>{attribute.name}</h3>
                                <p>{attribute.options[0]}</p>
                            </div >
                        ))}

                        <div>
                            {processedAttributes.map((attr, index) => (
                                <div key={index}>
                                    <h3>{toTitleCase(attr.name)}</h3>
                                    {attr.options.map((option, i) => (
                                        <p key={i} dangerouslySetInnerHTML={{ __html: addBreaksToStrongTags(option) }}></p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                }
                <SideBar isVisible={isSideBarVisible} toggleSideBar={toggleSideBar} />
            </>
        )
    };
};

export default Product;
