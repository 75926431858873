import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import axios from "axios";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import productData from '../data/productData';
import { Link } from 'react-router-dom';
import '../css/Recommends.css';

const Recommends = () => {
    const [recommendData, setRecommendData] = useState([]);

    const apiUrl = 'https://fm.access-point.xyz/wp-json/wc/';
    const consumerKey = process.env.REACT_APP_API_KEY;
    const consumerSecret = process.env.REACT_APP_API_SECRET;

    // Axios configuration
    const axiosProductsInstance = axios.create({
        baseURL: apiUrl,
        auth: {
            username: consumerKey,
            password: consumerSecret,
        },
        params: {
            // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
        }
    });

    const fetchData = useCallback(() => {
        axiosProductsInstance.get('v3/products?per_page=100').then(res => {
            setRecommendData(res.data.filter(product => product.tags.some(tag => tag.name === "recommended")));
        });
    }, []);


    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <div className="recommends">
            {/* Using data from productDta for demonstration */}
            <h3 className="recommends-title">Products You May Like</h3>
            <div className="recommends-wrapper">
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    // loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    centeredSlidesBounds={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    className="mySwiper"
                >
                    {recommendData.map(product => (
                        <SwiperSlide key={product.id} className="recommends-list">
                            <div className="recommends-image-wrapper">
                                <Link to={`/product/${product.id}`} className="recommends-image" style={{ backgroundImage: 'url(' + product.images[0].src + ')' }} ></Link>
                                {product.on_sale && <span className="sale-label">Sale</span>}
                            </div>
                            <div className="recommends-info">
                                <Link to={`/product/${product.id}`} className="recommends-list-title">{product.name}</Link>
                                {/* <p>{product.description}</p> */}
                                <div>
                                    <span className="price">
                                        ${product.price}
                                        <span className="price-uom">{product.meta_data[1] && product.meta_data[1].value}</span>
                                    </span>
                                    {product.on_sale &&
                                        <span className="regular-price">
                                            ${product.regular_price}
                                            <span className="price-uom">{product.meta_data[1] && product.meta_data[1].value}</span>
                                        </span>
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        </div>
    );
};

export default Recommends;
