import { createContext, useContext, useState, useEffect } from 'react';
import { axiosUserInstance, axiosUserDetailsInstance } from "../dataFetch/axios.main"
import axios from "axios"
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
// import { useCheckout } from './CheckoutContext';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        const isLogIn = localStorage.getItem('isLoggedIn');
        return isLogIn ? isLogIn : false;
    });

    const [jwt, setJwt] = useState(() => {
        const storedJwt = localStorage.getItem('jwt');
        return storedJwt ? storedJwt : undefined;
    });

    const [isGuest, setIsGuest] = useState(() => {
        const isGue = localStorage.getItem('isGuest');
        return isGue ? isGue : false;
    });

    const [userDetails, setUserDetails] = useState(() => {
        const userData = localStorage.getItem('userDetails');
        return userData ? JSON.parse(userData) : undefined;
    });
    const [user, setUser] = useState(() => {
        const userData = localStorage.getItem('user');
        return userData ? JSON.parse(userData) : undefined;
    });
    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);

    const getCustomerDetails = useMutation({
        mutationFn: (data) => {
            // console.log(data)
            console.log("Fetching Customer Information from getCustomerDetails")
            return axiosUserDetailsInstance.get(`v3/customers/${data.id}`);
        },
        onSuccess: async (response) => {
            console.log("Fetched Customer Information");
            console.log("getCustomerDetails: ", response);
            // setUserDetails(response.data.data.user);
            response.data.billing.country = "Canada";
            response.data.billing.state = "British Columbia";
            response.data.billing.first_name = response.data.first_name;
            response.data.billing.last_name = response.data.last_name;
            response.data.billing.email = response.data.email;
            response.data.shipping.country = "Canada";
            response.data.shipping.state = "British Columbia";
            response.data.shipping.first_name = response.data.first_name;
            response.data.shipping.last_name = response.data.last_name;
            response.data.shipping.email = response.data.email;

            localStorage.setItem('userDetails', JSON.stringify(response.data));
            setUserDetails(response.data)
        },
        onError: async (error) => {
            console.log("Failed to Fetch Customer Infromation");
            console.log(error)
        },
    });
    const authenticate = useMutation({
        mutationFn: (data) => {
            console.log("Signing In")
            return axios.post('https://fm.access-point.xyz/wp-json/simple-jwt-login/v1/auth/', {
                email: data.email,
                password: data.password,
                authCode: 'aafa2123fa'
            })
        },
        onSuccess: async (response) => {
            console.log("Success setting jwt..");
            console.log("authenticate response: ", response)
            localStorage.setItem('jwt', response.data.data.jwt)
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('isGuest', false)
            console.log("authenticate set isGuest to false")
            setJwt(response.data.data.jwt)
            setIsGuest(false);
            setIsLoggedIn(true);
            validateUser.mutate({ jwt: response.data.data.jwt })
        },
        onError: async (error) => {
            setIsGuest(true);
            console.log("Error");
            console.log(error);
        }
    });

    const validateUser = useMutation({
        mutationFn: (data) => {
            console.log("Validating")
            return axios.post('https://fm.access-point.xyz/wp-json/simple-jwt-login/v1/auth/validate', {
                JWT: data.jwt,
                authCode: 'aafa2123fa'
            })
        },
        onSuccess: async (response) => {
            console.log("Validation Success");
            console.log(response.data.data.user)
            setUser(response.data.data.user)
            localStorage.setItem('user', JSON.stringify(response.data.data.user))
            getCustomerDetails.mutate({ id: response.data.data.user.ID })

        },
        onError: async (error) => {
            console.log("Validation Error");
            // handle error here
        },

    });

    const updateCustomerShipping = useMutation({
        mutationFn: (data) => {
            return axios.put('https://fm.access-point.xyz/wp-json/simple-jwt-login/v1/users', {
                //   Billing data   
            })
        },
        onSuccess: async (response) => {
            console.log("Updated Customer Shipping")
            console.dir(response.data);

        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }
    })
    const updateCustomerBilling = useMutation({
        mutationFn: (data) => {
            console.log("Billing Payload");
            // console.log(data);
            return axiosUserDetailsInstance.put(`v3/customers/${data.customer_id}`, {
                //   Billing data   

                first_name: data.billing.firstName,
                billing: {
                    "first_name": data.billing.first_name,
                    "last_name": data.billing.last_name,
                    "address_1": data.billing.address_1,
                    "address_2": data.billing.address_2,
                    "city": data.billing.city,
                    "postcode": data.billing.postcode,
                    "country": data.billing.country,
                    "state": data.billing.state,
                    "email": data.billing.email,
                    "phone": data.billing.phone
                }

            })
        },
        onSuccess: async (response) => {
            console.log("Updated Customer Billing")
            // console.dir(response.data);

        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }
    })

    // // Send a welcome email to the user
    // const sendWelcomeEmail = useMutation({
    //     mutationFn: (data) => {
    //         return axios.post('https://fm.access-point.xyz/wp-json/custom/v1/welcome', {
    //             email: data.email,
    //             name: data.name,
    //         })
    //     },
    //     onSuccess: async (response) => {
    //         console.log("Welcome Email Sent")
    //         console.dir(response.data);
    //     },
    //     onError: async (error) => {
    //         console.log("Error:" + error)
    //     }
    // });

    const registerToWooCommerce = useMutation({
        mutationFn: (data) => {
            console.log("Registering to WooCommerce")
            return axiosUserInstance.put(`v3/customers/${data.id}`, {
                email: data.email,
                username: data.email,
            })
        },
        onSuccess: async (response) => {
            console.log("Registered to WooCommerce")
            console.dir(response.data);
        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }

    })

    const register = useMutation({
        mutationFn: (data) => {
            return axios.post('https://fm.access-point.xyz/wp-json/simple-jwt-login/v1/users', {
                email: data.email,
                password: data.password,
                first_name: data.firstName,
                last_name: data.lastName,
                AUTH_CODE: "aafa2123fa"
            })
        },
        onSuccess: async (response) => {
            console.log("Retrieved Customer Data")
            console.dir(response);

            registerToWooCommerce.mutate({ id: response.data.user.ID, email: response.data.user.user_email })
            // debugger;
            setUser(response.data.user);
            setJwt(response.data.jwt);
            setIsGuest(false);
            setIsLoggedIn(true);

            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('isGuest', false)
            console.log("register set isGuest to false")

            localStorage.setItem('jwt', response.data.jwt)

            validateUser.mutate({ jwt: response.data.jwt })
            // sendWelcomeEmail.mutate({ email: response.data.user.user_email, name: response.data.user.display_name })
        },
        onError: async (error) => {
            console.log("Error")
            console.log(error)
        }
    })

    const registerUser = async (firstName, lastName, email, password) => {
        register.mutate({ firstName: firstName, lastName: lastName, email: email, password: password })
    };

    const signOut = async () => {
        console.log("Logging Off")
        console.log("Clearing Data")
        setJwt(null)
        setIsGuest(true);
        setIsLoggedIn(false);
        setUser(undefined);
        setUserDetails(undefined);
        localStorage.removeItem('jwt')
        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('isGuest')
        localStorage.removeItem('user')
        localStorage.removeItem('userDetails');
        localStorage.removeItem('checkout-state');
        localStorage.removeItem('shippingInfo');
        localStorage.removeItem('billingInfo');
        window.location.href = '/';

        if (!isLoggedIn) {
            return true;
        }
    };

    return (
        <UserContext.Provider value={{ user, isGuest, getCustomerDetails, userDetails, validateUser, updateCustomerBilling, setUserDetails, authenticate, registerUser, signOut, setEmail, email, jwt, isLoggedIn }}>
            {children}
        </UserContext.Provider>
    );
};
